// dependencies
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import StrapiPage from '@templates/strapi-cms/Page'

const PageNotFound = props => {
  const data = useStaticQuery(graphql`
    query strapiPageNotFoundQuery {
      allStrapiPage(filter: { id: { eq: "page:4894" } }) {
        edges {
          node {
            ...StrapiPageFragment
          }
        }
      }
    }
  `)

  const pageProps = { ...props, data }

  return <StrapiPage {...pageProps} />
}

export default PageNotFound
